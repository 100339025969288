.close{
    width: 1.5rem;
    color: #fff;
    cursor: pointer;
    background-color: var(--primary-color)!important;
    border-radius: 0 0 0 90px;
}
.verification-modal-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    border: none;
    box-shadow: 24;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 60px;
    padding: 6rem;
}
.verification-otp-img img{
width: 100%;
}
.close-icon{
    text-align: right;
}
.close-icon svg{
    color: var(--primary);
    font-size: 3rem;
}
.modal-title{
    font-weight: bolder;
    color: var(--secondry);
}
.modal-content{
    margin-top: 1.5rem;
    font-size: 1.2rem;
}
.opt-input-container{
    margin-bottom: 3rem;
    margin-top: 1rem;
}
.otp-input input{
    width: 4rem !important;
    height: 5rem !important;
    border: none;
    background: var(--ligt-grey-100) 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
}
.text-primary-btn{
    cursor: pointer;
    color: var(--primary);
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
}
.btn-otp-wrapper{
  margin-top: 4rem;  
}
.error{
    color: var(--danger);
    font-size: 1.2rem;
    font-size: 600;
}
.otp-btn-primary{
    width: 22rem;
    padding: 1rem;
    background: var(--primary) 0% 0% no-repeat padding-box;
    border: none;
    color: white;
    font-size: medium;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-weight: 500;
}


@media only screen and (max-width: 1200px) {

    .otp-input input{
        width: 4rem !important;
        height: 5rem !important;
        border: none;
        background: var(--ligt-grey-100) 0% 0% no-repeat padding-box !important;
        border-radius: 5px !important;
        font-size: 1.1rem;
    }

    .otp-input input:focus{
        font-size: 1.1rem;
    }

    .row-flex-wrap{
        flex-wrap: wrap-reverse !important;
    }
    .verification-modal-wrapper{
        /* width: 90%; */
        padding: 2rem;
    }
    .otp-content-container{
        text-align: center;
    }
    .btn-otp-wrapper{
        margin-top: 2rem;
    }
    .opt-input-container{
        display: flex;
    justify-content: center;
    }
    .verification-otp-img{
        margin: auto;
    display: flex;
    justify-content: center;
    /* justify-items: center; */
    width: 50%;
    }
}
@media (max-width: 480px) {
    .verification-modal-wrapper{
        width: 90%;
        padding: 2rem;
    }
}
@media only screen and (max-width: 375px) {

}
.number-input-container input{
    background: var(--white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #00000029;
    border: 1px solid #70707080;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    padding: 0.3rem;
    height: 3rem;
    text-align: center;
}
.btn-primary{
    width: 15rem;
    padding: 0.5rem 1rem;
    background: var(--primary) 0% 0% no-repeat padding-box;
    border: none;
    color: white;
    font-size: medium;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-weight: 500;
    opacity: 1;
}
.number-input-container{
    width: 40%;
    margin: auto;
    margin-bottom: 2.5rem;
}
.input-wrapper{
    margin-top: 3rem;
}
.number-input-container input:focus-visible{
    border: 1px solid #70707080 !important;
}

@media only screen and (max-width: 1200px) {
    .number-input-container{
        width: 60%;
    }
    .number-input-container input{
        background: var(--white) 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 15px #00000029;
        border: 1px solid #70707080;
        font-size: 1.1rem;

        /* border-radius: 14px; */
    }

    .number-input-container input:focus{
        font-size: 1.1rem;
    }

}